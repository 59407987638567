import React, { useEffect } from "react";
import { Link } from "gatsby";
import TestimonialCallout from "../testimonial-callout/testimonial-callout";
import VideoModal from "../video-modal/video-modal";
import classImg from "../../images/mission_report_video_thumbnail.jpg";
import videoIcon from "../../images/icons/Icon-Play-Video-Blue.svg";
import Topics from "../../../content/topics.yml";
import Photo1 from "../../images/photos/Image-Helping-Our-Neighbors-Find-Homes.jpg";
import Photo2 from "../../images/photos/Image-Providing-Access-to-Healthy-Food.jpg";
import Photo3 from "../../images/photos/Image-Including-More-Rhode-Islanders.jpg";
import Photo4 from "../../images/photos/Image-Access-To-Behavioral-Health-Care.jpg";
import Photo5 from "../../images/photos/Image-A-Decade-of-Dedication.jpg";
import Photo6 from "../../images/photos/Image-New-Plan-for-Rhode-Island.jpg";
import Photo7 from "../../images/photos/Image-Listening-to-Providers.jpg";
import "./content-grid.css";

const ContentGrid = () => {
  const topics = Topics.reduce(
    (obj, topic) => ({ ...obj, [topic.slug]: topic }),
    {}
  );

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("content-grid__item--scrolled-into-view");
        }
      });
    });

    document
      .querySelectorAll(".content-grid__item")
      .forEach((element) => observer.observe(element));
  });

  const ContentItem = ({ topic, title, url, children }) => (
    <div
      className="content-grid__item"
      style={{ "--topic-color": topic.color }}
    >
      {topic.url ? (
        <Link className="content-grid__item__tag" to={topic.url}>
          {topic.slug}
        </Link>
      ) : (
        <span className="content-grid__item__tag">{topic.slug}</span>
      )}
      <div className="content-grid__item__title">{title}</div>
      <div className="content-grid__item__media">{children}</div>
      {url && (
        <Link className="content-grid__item__link" to={url}>
          Read More
        </Link>
      )}
    </div>
  );

  return (
    <div class="content-grid-container pt-none pb-none">
      <div className="content-grid">
        <div>
          <div class="content-grid__video content-grid__video-small">
            <ContentItem
              topic={{ slug: "video" }}
              title="A message from Martha Wofford"
            >
              <VideoModal
                ariaHideApp={false}
                caption={[<span>Watch now</span>]}
                posterImage={classImg}
                videoPlayIcon={videoIcon}
                videoSource="https://player.vimeo.com/video/690530045?h=9cb828a466"
                width={640}
                height={564}
                allow="autoplay; fullscreen"
              />
            </ContentItem>
          </div>
          <div className="content-grid">
            <div>
              <ContentItem
                topic={topics.community}
                title="Helping our neighbors find homes"
                url="/community#helping-our-neighbors-find-homes"
              >
                <img src={Photo1} alt="" loading="lazy" />
              </ContentItem>
            </div>
            <div class="content-grid-cell--with-top-offset">
              <ContentItem
                topic={topics.community}
                title="Providing access to healthy food"
                url="/community#providing-access-to-healthy-food"
              >
                <img src={Photo2} alt="" loading="lazy" />
              </ContentItem>
            </div>
          </div>
          <div className="content-grid">
            <div class="content-grid__testimonial">
              <TestimonialCallout
                grid
                author="Elliott Clement"
                role="BCBSRI member"
                url="/customers#bringing-your-blue-store-to-the-community"
                quote="For older people, it can be harder to get out of
                  chairs or up the stairs. Being active helps a lot, and I’d
                  tell my family and friends to come to the Blue Cross
                  classes."
              />
            </div>
          </div>
          <div className="content-grid">
            <div>
              <ContentItem
                topic={topics.customers}
                title="Including more Rhode Islanders"
                url="/customers#including-more-rhode-islanders"
              >
                <img src={Photo3} alt="" loading="lazy" />
              </ContentItem>
              <ContentItem
                topic={topics.community}
                title="A decade of dedication"
                image={Photo5}
                url="/community#celebrating-a-decade-of-dedication"
              >
                <img src={Photo5} alt="" loading="lazy" />
              </ContentItem>
            </div>
            <div class="content-grid-cell--with-top-offset content-grid-provider-partners">
              <ContentItem
                topic={{
                  slug: "provider partners",
                  url: "/provider-partners",
                }}
                title="Access to behavioral healthcare"
                url="/provider-partners#transforming-access-to-behavioral-healthcare"
              >
                <img src={Photo4} alt="" loading="lazy" />
              </ContentItem>
            </div>
          </div>
        </div>
        <div>
          <div className="content-grid">
            <div class="content-grid__video content-grid__video-large">
              <ContentItem
                topic={{ slug: "video" }}
                title="A message from Martha Wofford"
              >
                <VideoModal
                  ariaHideApp={false}
                  caption={[<span>Watch now</span>]}
                  posterImage={classImg}
                  videoPlayIcon={videoIcon}
                  videoSource="https://player.vimeo.com/video/690530045?h=9cb828a466"
                  width={640}
                  height={564}
                  allow="autoplay; fullscreen"
                />
              </ContentItem>
            </div>
          </div>
          <div className="content-grid">
            <div>
              <ContentItem
                topic={topics.customers}
                title="New plan for Rhode Island"
                url="/customers#making-health-more-accessible"
              >
                <img src={Photo6} alt="" loading="lazy" />
              </ContentItem>
            </div>
            <div class="content-grid-cell--with-top-offset content-grid-provider-partners">
              <ContentItem
                topic={{
                  slug: "provider partners",
                  url: "/provider-partners",
                }}
                title="Listening to providers"
                url="/provider-partners#listening-to-providers"
              >
                <img src={Photo7} alt="" loading="lazy" />
              </ContentItem>
            </div>
          </div>
          <div className="content-grid">
            <div class="content-grid__testimonial">
              <TestimonialCallout
                grid
                author="Marie Ghazal"
                role="CEO, Rhode Island Free Clinic"
                url="/provider-partners#expanding-health-equity-for-more-rhode-islanders"
                quote="One of the most important things about the survival of a
                  free clinic is to have strong partners. We couldn’t have done
                  what we’ve done without Blue Cross’ support."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentGrid;
