// Base
import React from "react";

// Global Layout
import Layout from "../components/layout/layout";

// Components
import Hero from "../components/hero/hero";
import FullWidthContainer from "../components/full-width-container/full-width-container";
import HomepageFinancial from "../components/homepage-financial/homepage-financial";
import SectionCallout from "../components/section-callout/section-callout";
import NestedContainer from "../components/nested-container/nested-container";
import { StaticImage } from "gatsby-plugin-image";
import ContentGrid from "../components/content-grid/content-grid";

const IndexPage = () => {
  return (
    <Layout pageTitle="2021 BCBSRI Mission Report - Homepage">
      <FullWidthContainer singleHero background={`var(--white-to-blue)`}>
        <Hero
          title="Mission Report"
          intro="All Rhode Islanders—from the youngest babies to the oldest great-grandparents—deserve the chance to live a healthy life. To grow up with a roof over our heads. Dinner at our own kitchen table. We deserve high-quality, affordable medical care and therapy when we need it. The people of Blue Cross & Blue Shield of Rhode Island are working to make that happen. The health and well-being of Rhode Islanders is what we live for."
          community
          customers
          partners
        >
          <StaticImage
            src="../images/photos/Image-Hero.jpg"
            alt="test"
            aspectRatio={19 / 17}
          />
        </Hero>
      </FullWidthContainer>
      <FullWidthContainer single styleName={`container--full--pb-0`} background={`var(--blue-to-white)`}>
        <NestedContainer>
          <ContentGrid />
        </NestedContainer>
      </FullWidthContainer>
      <FullWidthContainer single styleName={`container--full--pt-0 container--full--pb-0`} background={`var(--white)`}>
        <HomepageFinancial />
        <SectionCallout url="/financials" title="2021 Financial Results" />
      </FullWidthContainer>
    </Layout>
  );
};

export default IndexPage;
