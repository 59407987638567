import React from "react";
import NestedContainer from "../nested-container/nested-container";
import RowEqual from "../row/row-equal";
import HandGlobe from "../../images/icons/Icon-hand-globe.svg";
import HandHeart from "../../images/icons/Icon-hand-heart.svg";
import Calendar from "../../images/icons/Icon-calendar.svg";
import DollarSign from "../../images/icons/Icont-dollar-sign.svg";
import Tshirt from "../../images/icons/Icon-tshirt.svg";
import PiggyBank from "../../images/icons/Icon-piggy-bank.svg";

import "./homepage-financial.css";

const HomepageFinancial = () => (
  <div className="homepage-financial">
    <h2>Living Our Mission</h2>
    <NestedContainer background={"#F2F7F0"} border="thick-white" hasShadow>
      <RowEqual divider doubleGap styleName={"homepage-financial-grid"}>
        <div className="row-item">
          <div className="homepage-financial__icon">
            <img src={HandGlobe} alt="sample" />
          </div>
          <div className="homepage-financial__number">132</div>
          <div className="homepage-financial__desc">
            organizations supported through in-kind donations, volunteerism, or
            financial support
          </div>

          <div className="homepage-financial__icon">
            <img src={Calendar} alt="sample" />
          </div>
          <div className="homepage-financial__number">5,700</div>
          <div className="homepage-financial__desc">volunteer hours served</div>

          <div className="homepage-financial__icon">
            <img src={Tshirt} alt="sample" />
          </div>
          <div className="homepage-financial__number">$134,743</div>
          <div className="homepage-financial__desc">
            generated by employee fundraising
          </div>
        </div>

        <div className="row-item">
          <div className="homepage-financial__icon">
            <img src={HandHeart} alt="sample" />
          </div>
          <div className="homepage-financial__number">110,625</div>
          <div className="homepage-financial__desc">
            people impacted by charitable funding or
            <br />
            volunteerism
          </div>
          <div className="homepage-financial__icon">
            <img src={DollarSign} alt="sample" />
          </div>
          <div className="homepage-financial__number">$160,000</div>
          <div className="homepage-financial__desc">
            value of volunteer hours*
          </div>

          <div className="homepage-financial__icon">
            <img src={PiggyBank} alt="sample" />
          </div>
          <div className="homepage-financial__number">$2.6 million+</div>
          <div className="homepage-financial__desc">
            invested by the company through financial or in-kind donations or
            distributions from the Rhode Island Community Health Fund at the
            Rhode Island Foundation
          </div>
        </div>
      </RowEqual>
      <div className="homepage-financial__source">
        *Source: Independent Sector
      </div>
    </NestedContainer>
  </div>
);

export default HomepageFinancial;
